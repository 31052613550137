
import { emailValidator } from "@/utils/validators";
import { submitFeedback } from "@/http";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "suggest",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {
      form: {
        productType: "APP",
        des: "",
        email: "",
      },
      formRules: {
        des: {
          required: true,
          message: this.t("please_enter_content"),
          trigger: "change",
        },
        email: {
          validator: emailValidator,
          trigger: "change",
        },
      },
    };
  },
  methods: {
    async submitHandler() {
      // eslint-disable-next-line
      const formRef: any = this.$refs.form;
      formRef.validate(async (valid: boolean) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    async submit() {
      const { productType, des, email } = this.form;
      const isApp = productType === "APP";
      await submitFeedback({
        category: "suggestion",
        content: des,
        contact_info: email,
        source: isApp ? 1 : 2,
      });
      // todo toast
    },
  },
});
