<template>
  <div class="suggest g-safe-area">
    <div class="g-title">
      <img
        src="../../assets/img/arrow-left.svg"
        alt=""
        @click="$router.go(-1)"
      />
      <p>{{ t("feature_suggestions") }}</p>
    </div>
    <el-form :model="form" :rules="formRules" ref="form">
      <div class="g-container">
        <h5 class="g-h5">{{ t("select_type_of_product_for_feedback") }}</h5>
        <section class="g-section">
          <div class="g-radio-box">
            <el-radio v-model="form.productType" label="APP"
              >ONTO {{ t("App_wallet") }}</el-radio
            >
            <el-radio v-model="form.productType" label="extension">
              ONTO {{ t("extension_wallet") }}
            </el-radio>
          </div>
        </section>
      </div>

      <div class="g-container">
        <h5 class="g-must g-h5">{{ t("describe") }}</h5>
        <el-form-item prop="des">
          <section class="g-section">
            <el-input
              type="textarea"
              :rows="2"
              :placeholder="t('please_enter_content')"
              autosize
              maxlength="500"
              show-word-limit
              resize="none"
              v-model="form.des"
            ></el-input>
          </section>
        </el-form-item>
        <sub class="g-sub">{{ t("suggest_describe_sub") }}</sub>

        <h5 class="g-must g-h5 g-top64">{{ t("email") }}</h5>
        <el-form-item prop="email">
          <section class="g-section">
            <el-input
              v-model="form.email"
              :placeholder="t('please_enter_email')"
            ></el-input>
          </section>
        </el-form-item>
        <sub class="g-sub">{{ t("email_sub") }}</sub>
      </div>
    </el-form>
    <el-button type="primary" style="margin-top: 32px" @click="submitHandler">
      {{ t("submit") }}
    </el-button>
  </div>
</template>

<script lang="ts">
import { emailValidator } from "@/utils/validators";
import { submitFeedback } from "@/http";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "suggest",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {
      form: {
        productType: "APP",
        des: "",
        email: "",
      },
      formRules: {
        des: {
          required: true,
          message: this.t("please_enter_content"),
          trigger: "change",
        },
        email: {
          validator: emailValidator,
          trigger: "change",
        },
      },
    };
  },
  methods: {
    async submitHandler() {
      // eslint-disable-next-line
      const formRef: any = this.$refs.form;
      formRef.validate(async (valid: boolean) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    async submit() {
      const { productType, des, email } = this.form;
      const isApp = productType === "APP";
      await submitFeedback({
        category: "suggestion",
        content: des,
        contact_info: email,
        source: isApp ? 1 : 2,
      });
      // todo toast
    },
  },
});
</script>

<style scoped lang="scss"></style>
